<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0px;"
  >
    <h4>Versions</h4>
    <div
      class="overflow-auto flex-shrink-1"
      style="min-height: 0px;"
    >
      <table class="vuetable custom-vuetable table sticky-table-header">
        <thead>
          <tr>
            <th>Number</th>
            <th>Description</th>
            <th>Locked</th>
            <th>Creator</th>
            <th>Last Updated</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="v in versions"
            :key="v.id"
          >
            <td>
              <router-link :to="{ name: 'list_aim_rules', params: { number: v.number, sheetNumber: 0 } }">
                {{ v.number }}
              </router-link>
            </td>
            <td>{{ v.descr }}</td>
            <td>{{ v.locked ? 'Yes' : 'No' }}</td>
            <td>{{ v.creator.name }}</td>
            <td>{{ formatDateTime(v.updated_on) }}</td>
            <td>
              <a
                v-if="v.locked"
                :href="downloadLink(v)"
                class="link-list"
              >
                <font-awesome-icon icon="download" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </p>
    <div class="noprint d-flex mt-2">
      <button
        class="btn btn-primary mr-2"
        @click.prevent="$refs.upload.show"
      >
        New
      </button>
      <button
        v-if="latestVersion > 1"
        class="btn btn-primary mr-2"
        @click.prevent="$refs.diff.show"
      >
        Compare
      </button>
      <button
        class="btn btn-default"
        @click.prevent="$router.go(-1)"
      >
        Back
      </button>
    </div>
    <VersionCreate
      ref="upload"
      :aim-id="aimId"
      :project-id="projectId"
      :can-create="canCreate"
      :latest-version="latestVersion"
    />
    <VersionDiffModal
      :id="aimId"
      ref="diff"
      :latest-version="latestVersion"
    />
  </div>
</template>

<script>
/**
 * Lists Versions for a Application Interface Matrix
 */
import VersionCreate from './VersionCreate'
import VersionDiffModal from './VersionDiffModal'
import aimAPI from '@/api/aim'
import projectAPI from '@/api/project'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    VersionCreate,
    VersionDiffModal,
  },
  props: {
    aimId: {
      type: [String, Number],
      required: true,
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      error: '',
      versions: [],
    }
  },
  computed: {
    canCreate () {
      return this.versions.findIndex(v => !v.locked) < 0
    },
    latestVersion () {
      return this.versions.length > 0 ? this.versions[0].number : 0
    },
  },
  beforeMount () {
    this.getVersions()
  },
  methods: {
    getVersions () {
      aimAPI.getVersions(this.aimId).then(resp => {
        this.versions = resp
      }).catch(error => {
        this.error = error.message
      })
    },
    downloadLink (v) {
      if (v.attachment_id) {
        return process.env.VUE_APP_API_URL + projectAPI.attachmentDownloadURL(v.attachment_id)
      } else {
        return process.env.VUE_APP_API_URL + aimAPI.versionDownloadURL(this.aimId, v.number)
      }
    },
    formatDateTime,
  },
}
</script>
