<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0;"
  >
    <div
      v-if="canCreateAim"
      class="mb-3"
    >
      <button
        class="btn btn-primary"
        @click.prevent="$refs.createMatrix.show"
      >
        Create New AIM
      </button>
    </div>
    <ListTemplate
      ref="customtable"
      :fields="fields"
      :delete-action="false"
      :update-action="false"
      :create-action="false"
      default-sort="id desc"
      :api-url="apiURL"
    />
    <AIMCreate
      ref="createMatrix"
      :project-id="projectId"
      @create-cm="redirectToMatrix"
    />
  </div>
</template>

<script>
/**
 * View to list all Application Interface Matrices
 */
import AIMCreate from './AIMCreate'
import ListTemplate from '@/components/ListTemplate'
import aimAPI from '@/api/aim'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    AIMCreate,
    ListTemplate,
  },
  props: {
    // project id
    projectId: {
      type: [String, Number],
      required: true,
    },
    canCreateAim: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      teams: [],
      fields: [
        {
          name: 'id',
          visible: false,
        },
        {
          name: 'name',
          title: 'Name',
          sortField: 'name',
          filterField: 'name_like',
          filterType: String,
          linkToIndex: true,
        },
        {
          name: 'creator.name',
          title: 'Creator',
          filterField: 'creator.name_like',
          filterType: String,
        },
        {
          name: 'created_on',
          title: 'Created',
          formatter: this.formatDate,
        },
      ],
    }
  },
  computed: {
    apiURL () {
      return aimAPI.aimsURL(this.projectId)
    },
  },
  methods: {
    redirectToMatrix (cm) {
      this.$router.push({ name: 'list_aim_versions', params: { aimId: cm.id } })
    },
    formatDate (value) {
      return this.formatDateTime(value, 'YYYY-MM-DD HH:mm')
    },
    formatDateTime,
  },
}
</script>
