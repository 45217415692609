<template>
  <Modal
    ref="modal"
    title="Update Status"
  >
    <BaseForm
      submit-title="Update"
      @submit="update"
      @cancel="cancel"
    >
      <BaseInput
        v-model="status"
        title="Status"
        name="status"
        type="text"
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * a Modal component that creates a new AIM.
 * 'create-cm' event with aim is emitted when created.
 */
import FieldError from '@/components/FieldError'
import Modal from '@/components/Modal'
import aimAPI from '@/api/aim'

export default {
  components: {
    FieldError,
    Modal,
  },
  data () {
    return {
      verId: '',
      ruleId: '',
      status: '',
      error: '',
    }
  },
  methods: {
    reset () {
      this.verId = ''
      this.ruleId = ''
      this.status = ''
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal
     */
    show (verId, ruleId, status) {
      this.reset()
      this.verId = verId
      this.ruleId = ruleId
      this.status = status
      this.$refs.modal.show()
    },
    update () {
      const status = this.status === '' ? null : this.status
      aimAPI.updateRuleStatus(this.verId, this.ruleId, status).then(resp => {
        this.cancel()
        this.$emit('update', resp)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
