<template>
  <div
    class="d-flex flex-column"
    style="min-height: 0px;"
  >
    <div class="d-flex justify-content-between">
      <h4>Version {{ number }}</h4>
      <div>
        <button
          v-if="canUpdate"
          class="btn btn-sm btn-primary"
          @click.prevent="$refs.ruleUpdate.show"
        >
          New Rule
        </button>
      </div>
    </div>
    <div
      class="d-flex flex-wrap-reverse border-left"
      style="font-size: 12px;"
    >
      <div
        v-for="(s, index) in version.sheets"
        :key="s.name"
        class="border-top border-right text-nowrap"
      >
        <div
          v-if="index === sheetNumber"
          class="p-1"
          style="background-color: var(--gray-100); font-weight: 600;"
        >
          {{ s.name }}
        </div>
        <router-link
          v-else
          :to="{ params: { sheetNumber: index } }"
          class="text-nowrap p-1 inactive-sheet-tab"
          style="color: var(--gray-600);"
          tag="div"
          replace
        >
          {{ s.name }}
        </router-link>
      </div>
    </div>
    <div class="overflow-auto mb-2 custom-vuetable-wrapper flex-shrink-1">
      <table
        v-if="fields && fields.length > 0 "
        class="custom-vuetable table sticky-table-header"
      >
        <thead>
          <tr>
            <th
              v-for="(f, i) in fields"
              :key="`${f}_${i}`"
            >
              <div>{{ f }}</div>
            </th>
            <th><div>CR Number</div></th>
            <th><div>Implementation Date</div></th>
            <th><div>Status</div></th>
            <th v-if="canUpdate">
              <div>Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(r, rn) in rules"
            :key="r.id"
          >
            <td
              v-for="(v, i) in rowData(r)"
              :key="i"
              :class="{ 'aim-field-error': isInvalidField(r, i) }"
              @dblclick="clickRule(r, rn, i)"
            >
              <div
                v-if="isInvalidField(r, i)"
                v-tooltip="{
                  content: fieldError(r, i),
                  placement: 'top-center',
                  offset: '5',
                }"
                class="w-100"
              >
                {{ v }}
              </div>
              <template v-else-if="Array.isArray(v)">
                <div
                  v-for="val in v"
                  :key="val"
                  class="textfield"
                >
                  <span>{{ val }}</span>
                </div>
              </template>
              <template v-else-if="v !== ''">
                {{ v }}
              </template>
              <template v-else>
                &nbsp;
              </template>
            </td>
            <td
              class="aimClickable"
              @click="updateCR(r)"
            >
              {{ r.cr_number }}
            </td>
            <td
              class="aimClickable"
              @click="updateImpDate(r)"
            >
              {{ formatDate(r.implemented_on) }}
            </td>
            <td
              class="aimClickable"
              @click="updateStatus(r)"
            >
              {{ r.status }}
            </td>
            <td
              v-if="canUpdate"
              style="white-space: nowrap;"
            >
              <button
                class="btn btn-icon btn-primary"
                title="Edit"
                @click.prevent="editRule(r)"
              >
                <EditIcon title="Edit" />
              </button>
              <button
                class="btn btn-icon btn-primary"
                title="Delete"
                @click.prevent="confirmDelete(r)"
              >
                <TrashIcon title="Delete" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <FieldError v-model="error" />
    <div>
      <button
        v-if="canUpdate"
        class="btn btn-primary"
        @click.prevent="$refs.versionSave.show"
      >
        Save
      </button>
      <button
        class="btn btn-default"
        @click.prevent="$router.go(-1)"
      >
        Back
      </button>
    </div>
    <VersionSave
      ref="versionSave"
      :version="version"
      @save-version="getData"
    />
    <RuleCRUpdate
      ref="crUpdate"
      @updatecr="updateRuleCR"
    />
    <RuleStatusUpdate
      ref="statusUpdate"
      @update="updateRuleStatus"
    />
    <RuleImpDateUpdate
      ref="impDateUpdate"
      @update="updateRuleImpDate"
    />
    <RuleUpdate
      ref="ruleUpdate"
      :version="version"
      :sheet="sheet"
      @update="updateRule"
      @create="addRule"
    />
    <ConfirmDialog
      ref="dialog"
      @confirmed="deleteRule"
    />
  </div>
</template>

<script>
import RuleUpdate from './RuleUpdate'
import RuleCRUpdate from './RuleCRUpdate'
import RuleStatusUpdate from './RuleStatusUpdate'
import RuleImpDateUpdate from './RuleImpDateUpdate'
import VersionSave from './VersionSave'
import TrashIcon from 'vue-material-design-icons/TrashCanOutline.vue'
import EditIcon from 'vue-material-design-icons/SquareEditOutline.vue'
import ConfirmDialog from '@/components/ConfirmDialog'
import FieldError from '@/components/FieldError'
import aimAPI from '@/api/aim'
import formatDate from '@/filters/formatDate'

export default {
  name: 'VersionShow',
  components: {
    RuleUpdate,
    RuleImpDateUpdate,
    RuleStatusUpdate,
    RuleCRUpdate,
    ConfirmDialog,
    TrashIcon,
    EditIcon,
    FieldError,
    VersionSave,
  },
  props: {
    aimId: {
      type: [String, Number],
      required: true,
    },
    number: {
      type: [String, Number],
      required: true,
    },
    sheetNumber: {
      type: [String, Number],
      required: true,
    },
    canCreateAim: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      version: {
        locked: true,
        sheets: [],
      },
      error: '',
    }
  },
  computed: {
    customFieldIndexes () {
      if (!this.fields) {
        return new Map()
      }
      return new Map([
        [this.sheet.source_index, 'source_addrs'],
        [this.sheet.dest_index, 'dest_addrs'],
        [this.sheet.port_index, 'ports'],
        // [this.sheet.proto_index, 'protocol']
      ])
    },
    sheet () {
      if (this.version.sheets) {
        return this.version.sheets[this.sheetNumber] || {}
      }
      return {}
    },
    fields () {
      return this.sheet ? this.sheet.fields || [] : []
    },
    rules () {
      return this.sheet ? this.sheet.rules || [] : []
    },
    canUpdate () {
      return !this.version.locked && this.canCreateAim
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      aimAPI.getVersion(this.aimId, this.number).then(resp => {
        this.version = resp
      }).catch(error => {
        this.error = error.message
      })
    },
    confirmDelete (r) {
      this.$refs.dialog.confirm(
        'Confirm Rule Delete',
        'Are you sure you want to delete?',
        r,
      )
    },
    deleteRule (r) {
      aimAPI.deleteRule(this.version.id, r.id).then(resp => {
        this.error = ''
        const i = this.rules.findIndex(s => s.id === r.id)
        if (i >= 0) {
          this.rules.splice(i, 1)
        }
      }).catch(error => {
        this.error = error.message
      })
    },
    isCustomField (i) {
      return this.customFieldIndexes.has(i)
    },
    isInvalidField (r, i) {
      return this.isCustomField(i) && r.data[i] !== ''
    },
    fieldError (r, i) {
      if (i === this.sheet.source_index) {
        return r.source_error
      } else if (i === this.sheet.dest_index) {
        return r.dest_error
      } else if (i === this.sheet.port_index) {
        return r.port_error
      }
      return ''
    },
    rowData (r) {
      return r.data.map((v, i, a) => {
        if (!this.isCustomField(i) || a[i] !== '') {
          return a[i]
        }
        return r[this.customFieldIndexes.get(i)]
      })
    },
    textRowData (r) {
      return this.rowData(r).map(v => Array.isArray(v) ? v.join('\n') : v)
    },
    clickRule (r, rn, i) {
      if (!this.version || !this.canUpdate) {
        return
      }
      this.$refs.ruleUpdate.show(r.id, this.textRowData(r), i)
    },
    addRule (r) {
      this.rules.push(r)
    },
    updateRule (r) {
      const i = this.sheet.rules.findIndex(s => s.id === r.id)
      if (i >= 0) {
        this.$set(this.sheet.rules, i, r)
      }
    },
    editRule (r) {
      this.$refs.ruleUpdate.show(r.id, this.textRowData(r), 0)
    },
    updateCR (r) {
      this.$refs.crUpdate.show(r.aim_version_id, r.id, r.cr_number)
    },
    updateStatus (r) {
      this.$refs.statusUpdate.show(r.aim_version_id, r.id, r.status)
    },
    updateImpDate (r) {
      this.$refs.impDateUpdate.show(r.aim_version_id, r.id, r.implemented_on)
    },
    updateRuleCR (u) {
      const i = this.sheet.rules.findIndex(r => r.id === u.id)
      if (i >= 0) {
        this.$set(this.sheet.rules[i], 'cr_number', u.cr_number)
      }
    },
    updateRuleStatus (u) {
      const i = this.sheet.rules.findIndex(r => r.id === u.id)
      if (i >= 0) {
        this.$set(this.sheet.rules[i], 'status', u.status)
      }
    },
    updateRuleImpDate (u) {
      const i = this.sheet.rules.findIndex(r => r.id === u.id)
      if (i >= 0) {
        this.$set(this.sheet.rules[i], 'implemented_on', u.implemented_on)
      }
    },
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.inactive-sheet-tab:hover {
  cursor: pointer;
  background-color: var(--gray-100);
}

table {
  font-size: 12px;

  .textfield {
    white-space: pre;
  }
  .textar {
    line-height: normal;
    resize: none;
    overflow: hidden;
    display: block;
    width: 100%;
    white-space: pre;
    min-width: fit-content;
  }
  .editCell {
    padding: 0px 0px;
  }
  .aimClickable {
    padding: 2px 2px;

    &:hover {
      box-shadow: inset 0px 0px 0px 2px #2185d0;
    }
  }
}

.aim-field-error {
  background-color: rgb(247, 200, 200);
}
</style>
