<template>
  <Modal
    ref="modal"
    :title="`${title} Rule`"
    extra-large
  >
    <div class="d-flex justify-content-end">
      <div class="custom-control custom-switch">
        <input
          id="customSwitch1"
          v-model="showAllFields"
          type="checkbox"
          class="custom-control-input"
        >
        <label
          class="custom-control-label user-select-none"
          for="customSwitch1"
        >Show all fields</label>
      </div>
    </div>
    <BaseForm
      :submit-title="title"
      @submit="submit"
      @cancel="cancel"
    >
      <div class="overflow-auto my-3 rule-edit-wrapper">
        <table class="mb-0">
          <thead>
            <tr>
              <th
                v-for="(f, i) in row"
                v-show="showAllFields || requiredFieldIndexes.includes(i)"
                :key="`${sheet.fields[i]}_${i}_hdr`"
                class="border-gray-300"
              >
                {{ sheet.fields[i] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(f, i) in row"
                v-show="showAllFields || requiredFieldIndexes.includes(i)"
                :key="`${sheet.fields[i]}_${i}`"
                class="p-0 border-gray-300"
              >
                <textarea
                  ref="fields"
                  v-model="row[i]"
                  :autofocus="i === 2"
                  rows="15"
                  cols="20"
                  trim
                  class="d-block border-1"
                  style="width: 200px; border-width: 1px; padding: 2px; resize: none;"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FieldErrorList v-model="errors" />
    </BaseForm>
  </Modal>
</template>

<script>
import FieldErrorList from '@/components/FieldErrorList'
import Modal from '@/components/Modal'
import aimAPI from '@/api/aim'

export default {
  components: {
    Modal,
    FieldErrorList,
  },
  props: {
    version: {
      type: Object,
      required: true,
    },
    sheet: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      id: '',
      row: [],
      showAllFields: false,
      errors: [],
    }
  },
  computed: {
    title () {
      return !this.id ? 'Create' : 'Update'
    },
    requiredFieldIndexes () {
      return [this.sheet.source_index, this.sheet.dest_index, this.sheet.port_index, this.sheet.proto_index]
    },
  },
  methods: {
    reset () {
      this.id = ''
      this.name = ''
      this.errors = []
      this.showAllFields = false
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    show (id, data, cellIndex) {
      this.reset()
      this.id = id
      if (!this.id) {
        this.row = Array(this.sheet.fields.length).fill('')
      } else {
        this.row = data
      }
      this.showAllFields = false
      this.$refs.modal.show()
      if (id && cellIndex >= 0) {
        this.$nextTick(() => { this.$refs.fields[cellIndex].focus() })
      }
    },
    update () {
      aimAPI.updateRule(this.version.id, { id: this.id, data: this.row }).then(resp => {
        this.cancel()
        this.$emit('update', resp)
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors
        } else {
          this.errors = [error.message]
        }
      })
    },
    create () {
      aimAPI.createRule(this.version.id, { data: this.row, aim_sheet_id: this.sheet.id }).then(resp => {
        this.cancel()
        this.$emit('create', resp)
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors
        } else {
          this.errors = [error.message]
        }
      })
    },
    submit () {
      this.errors = []
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  cursor: auto;
  scrollbar-width: thin;
  scrollbar-color: $gray-300 white;
  border: 1px solid $gray-300;
}
textarea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
textarea::-webkit-scrollbar-thumb {
  background: $gray-300;
}
textarea:focus {
    outline: none !important;
    border: 1px solid $gray-500;
    scrollbar-color: $gray-500 white;
}
textarea:focus::-webkit-scrollbar-thumb {
  background: $gray-500;
}
.rule-edit-wrapper {
  scrollbar-width: thin;
  scrollbar-color: $gray-300 white;
}
.rule-edit-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.rule-edit-wrapper::-webkit-scrollbar-thumb {
  background: $gray-300;
}
</style>
