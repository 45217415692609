<template>
  <Modal
    ref="modal"
    title="Compare AIM Versions"
    scrollable
  >
    <div v-if="latestVersion < 2">
      <p>Not enough versions to compare!</p>
      <button
        class="btn btn-default"
        @click="cancel"
      >
        Cancel
      </button>
    </div>
    <BaseForm
      v-else
      submit-title="Submit"
      @submit="submit"
      @cancel="cancel"
    >
      <BaseInputNumber
        v-model.number="num1"
        title="Version"
        name="num1"
        :max="latestVersion"
        :min="1"
        required
      />
      <BaseInputNumber
        v-model.number="num2"
        title="Version"
        name="num2"
        :max="latestVersion"
        :min="1"
        required
      />
    </BaseForm>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal,
  },
  props: {
    /** AIM version ID */
    id: {
      type: [String, Number],
      required: true,
    },
    latestVersion: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      num1: 0,
      num2: 0,
    }
  },
  methods: {
    cancel () {
      this.$refs.modal.hide()
    },
    show () {
      this.num1 = this.latestVersion - 1
      this.num2 = this.latestVersion
      this.$refs.modal.show()
    },
    submit () {
      this.$router.push({ name: 'aimsdiff', params: { num1: this.num1, num2: this.num2 } })
    },
  },
}
</script>
