<template>
  <div
    class="d-flex flex-column"
    style="min-height: 0px;"
  >
    <h3>AIM: {{ matrix.name }}</h3>
    <p
      v-if="error"
      class="text-danger"
    >
      {{ error }}
    </p>
    <router-view
      v-if="matrix.id"
      :can-create-aim="canCreateAim"
      :project-id="matrix.project_id"
    />
  </div>
</template>

<script>
import aimAPI from '@/api/aim'

export default {
  props: {
    /** application interface matrix ID */
    aimId: {
      type: [String, Number],
      required: true,
    },
    canCreateAim: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      canEdit: false,
      matrix: { name: '' },
      error: '',
    }
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        aimAPI.getAIM(this.aimId),
      ]).then(([matrix, users]) => {
        this.matrix = matrix
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
