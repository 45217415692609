<template>
  <Modal
    ref="modal"
    title="New AIM Version"
  >
    <div v-if="!canCreate">
      <p>Latest version must be saved before creating a new version!</p>
      <button
        class="btn btn-default"
        @click="cancel"
      >
        Cancel
      </button>
    </div>
    <BaseForm
      v-else
      submit-title="Submit"
      :loading="loading"
      @submit="submit"
      @cancel="cancel"
    >
      <label>Create new version from:</label>
      <div class="form-group">
        <div class="custom-control custom-radio">
          <input
            id="customRadio1"
            v-model="fromAttachment"
            :value="1"
            type="radio"
            name="customRadio1"
            class="custom-control-input"
          >
          <label
            class="custom-control-label"
            for="customRadio1"
          >
            Project Attachment
          </label>
        </div>
      </div>
      <template v-if="fromAttachment === 1">
        <BaseSelect
          v-model="filename"
          :options="filenames"
          title="Filename"
          name="filename"
          required
        />
        <BaseSelect
          v-model="attachment"
          :options="filteredAttachments"
          :custom-label="attachmentLabel"
          title="Version (upload date)"
          name="attachment"
          label="created_on"
          track-by="id"
          open-direction="bottom"
          required
        />
      </template>
      <template v-if="aimAttachmentTypeId">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              id="customRadio2"
              v-model="fromAttachment"
              :value="2"
              type="radio"
              name="customRadio2"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="customRadio2"
            >
              File Upload
            </label>
          </div>
        </div>
        <template v-if="fromAttachment === 2">
          <BaseInputFile
            v-model="file"
            :max-size="maxFileSize"
            title="File"
            name="file"
            type="file"
            required
          />
        </template>
      </template>
      <template v-if="latestVersion">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              id="customRadio3"
              v-model="fromAttachment"
              :value="3"
              type="radio"
              name="customRadio3"
              class="custom-control-input"
            >
            <label
              class="custom-control-label"
              for="customRadio3"
            >
              Current version
            </label>
          </div>
        </div>
      </template>
      <BaseInputCheckbox
        v-if="errors && errors.length > 0"
        v-model="ignoreErrors"
        title="Ignore Errors"
        name="ignore_errors"
      />
      <FieldErrorList v-model="errors" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * a Modal component that creates a new AIM.
 * 'create-cm' event with aim is emitted when created.
 */
import Modal from '@/components/Modal'
import FieldErrorList from '@/components/FieldErrorList'
import aimAPI from '@/api/aim'
import projectAPI from '@/api/project'
import formatDateTime from '@/filters/formatDateTime'
import { mapState } from 'vuex'

export default {
  components: {
    FieldErrorList,
    Modal,
  },
  props: {
    aimId: {
      type: [String, Number],
      required: true,
    },
    projectId: {
      type: [String, Number],
      required: true,
    },
    latestVersion: {
      type: [String, Number],
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      filename: '',
      file: null,
      fromAttachment: 1,
      attachment: null,
      attachments: [],
      aimAttachmentTypeId: 0,
      aimAttachmentTypeName: 'Application Interface Matrix',
      errors: [],
      ignoreErrors: false,
      loading: false,
    }
  },
  computed: {
    // get valid unique sorted filenames
    filenames () {
      return this.attachments
        .map(a => a.filename)
        .filter(fn => fn.endsWith('.xlsx') || fn.endsWith('.xlsm'))
        .sort((a, b) => a.localeCompare(b))
        .filter((fn, i, fnlist) => i === fnlist.indexOf(fn))
    },
    // get attachments matching filename, sorted by newest first
    filteredAttachments () {
      return this.attachments
        .filter(a => a.filename === this.filename)
        .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
    },
    maxFileSize () {
      return this.currentUser.max_attachment_size_mb * 1024
    },
    ...mapState('user', ['currentUser']),
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      Promise.all([
        projectAPI.getAttachments(this.projectId),
        projectAPI.getAttachmentTypes(this.projectId),
      ]).then(([attachments, attachmentTypes]) => {
        this.attachments = attachments.data
        if (attachmentTypes.data) {
          const aimAttach = attachmentTypes.data.find(at => at.name === this.aimAttachmentTypeName)
          if (aimAttach && aimAttach.id) {
            this.aimAttachmentTypeId = aimAttach.id
          }
        }
      }).catch(error => {
        this.errors.push(error.message)
      })
    },
    attachmentLabel (a) {
      if (!a.id) { return '' }
      return formatDateTime(a.created_on)
    },
    reset () {
      this.fromAttachment = 1
      this.filename = ''
      this.file = null
      this.attachment = null
      this.errors = []
      this.ignoreErrors = false
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal
     */
    show () {
      this.reset()
      this.$refs.modal.show()
    },
    submit () {
      if (this.fromAttachment === 1) {
        this.createVersion()
      } else if (this.fromAttachment === 2) {
        this.uploadVersion()
      } else if (this.fromAttachment === 3) {
        this.copyVersion()
      }
    },
    createVersion () {
      const version = {
        attachment_id: this.attachment.id,
        descr: 'Attachment: ' + this.attachment.filename,
        ignore_errors: this.ignoreErrors,
      }
      this.loading = true
      aimAPI.createVersion(this.aimId, version).then(resp => {
        this.cancel()
        const params = { number: resp.number, sheetNumber: 0 }
        this.$router.push({ name: 'list_aim_rules', params: params })
        this.$emit('create-aim-version', resp)
        this.loading = false
      }).catch(error => {
        if (error && error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors
        } else {
          this.errors = [error.message]
        }
        this.loading = false
      })
    },
    uploadVersion () {
      const formData = new FormData()
      // set file name to work around IE including full path in attachment upload
      const name = this.aimAttachmentTypeId + '_' + this.aimAttachmentTypeName
      formData.append(name, this.file, this.file.name)
      projectAPI.uploadAttachment(this.projectId, formData).then(attachment => {
        if (attachment.data && attachment.data.length === 1) {
          this.attachment = attachment.data[0]
          this.attachments.push(this.attachment)
          this.filename = this.attachment.filename
          this.file = null
          this.fromAttachment = 1
          this.createVersion()
        }
      }).catch(error => {
        this.errors.push(error.message)
      })
    },
    copyVersion () {
      this.loading = true
      aimAPI.copyVersion(this.aimId).then(resp => {
        this.cancel()
        const params = { number: resp.number, sheetNumber: 0 }
        this.$router.push({ name: 'list_aim_rules', params: params })
        this.$emit('create-aim-version', resp)
        this.loading = false
      }).catch(error => {
        this.errors = [error.message]
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>
.custom-control-label {
  font-weight: normal;
}
</style>
