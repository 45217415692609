<template>
  <Modal
    ref="modal"
    title="Create Application Interface Matrix"
  >
    <BaseForm
      submit-title="Create"
      @submit="create"
      @cancel="cancel"
    >
      <BaseInput
        v-model="name"
        :maxlength="32"
        title="Name"
        name="name"
        type="text"
        required
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * a Modal component that creates a new AIM.
 * 'create-cm' event with aim is emitted when created.
 */
import FieldError from '@/components/FieldError'
import Modal from '@/components/Modal'
import aimAPI from '@/api/aim'

export default {
  components: {
    FieldError,
    Modal,
  },
  props: {
    // project id
    projectId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      name: '',
      error: '',
    }
  },
  methods: {
    reset () {
      this.name = ''
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal
     */
    show () {
      this.reset()
      this.$refs.modal.show()
    },
    create () {
      aimAPI.createAIM(this.projectId, { name: this.name }).then(resp => {
        this.cancel()
        this.$emit('create-cm', resp)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
