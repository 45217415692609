import axios from '@/axios/default'

async function axiosData (promise) {
  const resp = await promise
  return resp.data
}

export default {
  aimsURL (projectId) {
    return `/projects/${projectId}/aims`
  },
  async createAIM (projId, a) {
    return axiosData(axios.post(this.aimsURL(projId), a))
  },
  aimURL (id) {
    return '/aims/' + id
  },
  async getAIM (id) {
    return axiosData(axios.get(this.aimURL(id)))
  },
  async updateAIM (a) {
    return axiosData(axios.put(this.aimURL(a.id), a))
  },
  versionsURL (aimId) {
    return this.aimURL(aimId) + '/versions'
  },
  async getVersions (aimId) {
    return axiosData(axios.get(this.versionsURL(aimId), { params: { sort: 'number desc' } }))
  },
  versionURL (aimId, number) {
    return this.versionsURL(aimId) + '/' + number
  },
  async getVersion (aimId, number) {
    return axiosData(axios.get(this.versionURL(aimId, number)))
  },
  versionDownloadURL (aimId, number) {
    return this.versionsURL(aimId) + '/' + number + '/download'
  },

  async createVersion (aimId, ver) {
    return axiosData(axios.post(this.versionsURL(aimId), ver))
  },
  async copyVersion (aimId) {
    return axiosData(axios.post(this.aimURL(aimId) + '/copy_version', {}))
  },
  async updateVersion (ver) {
    return axiosData(axios.put(this.versionURL(ver.aim_id, ver.number), ver))
  },

  async getVersionDiff (aimId, num1, num2) {
    return axiosData(axios.get(`/aims/${aimId}/diff/${num1}/${num2}`))
  },

  aimRulesURL (aimId, number) {
    return this.aimVersionURL(aimId, number) + '/rules'
  },
  async getRules (verId) {
    return axiosData(axios.get(this.aimRulesURL(verId)))
  },
  async createRule (verId, rule) {
    return axiosData(axios.post(`/aims_versions/${verId}/rules`, rule))
  },
  aimRuleURL (id) {
    return '/aim_rules/' + id
  },
  async updateRule (verId, rule) {
    return axiosData(axios.put(`/aims_versions/${verId}/rules/${rule.id}`, rule))
  },
  async deleteRule (verId, ruleId) {
    return axiosData(axios.delete(`/aims_versions/${verId}/rules/${ruleId}`))
  },

  async updateRuleCR (verId, ruleId, crNumber) {
    return axiosData(axios.put(`/aims_versions/${verId}/rules/${ruleId}/cr_number`, { cr_number: crNumber }))
  },
  async updateRuleStatus (verId, ruleId, status) {
    return axiosData(axios.put(`/aims_versions/${verId}/rules/${ruleId}/status`, { status: status }))
  },
  async updateRuleImpDate (verId, ruleId, impOn) {
    return axiosData(axios.put(`/aims_versions/${verId}/rules/${ruleId}/implemented_on`, { implemented_on: impOn }))
  },
}
