<template>
  <div v-if="Array.isArray(value) && value.length > 0">
    <div
      v-for="(err, index) in value"
      :key="err"
      class="alert alert-danger overflow-hidden"
      style="text-overflow: ellipsis;"
    >
      {{ err }}
      <button
        type="button"
        class="close"
        @click.prevent="deleteError(index)"
      >
        &times;
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default () {
        return []
      },
    },
  },
  methods: {
    deleteError (i) {
      const errors = [...this.value]
      errors.splice(i, 1)
      this.$emit('input', errors)
    },
  },
}
</script>
