<template>
  <Modal
    ref="modal"
    title="Update Implementation Date"
  >
    <BaseForm
      submit-title="Update"
      @submit="update"
      @cancel="cancel"
    >
      <BaseInputDate
        v-model="implemented_on"
        title="Implementation Date"
        name="implemented_on"
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * a Modal component that creates a new AIM.
 * 'create-cm' event with aim is emitted when created.
 */
import FieldError from '@/components/FieldError'
import Modal from '@/components/Modal'
import aimAPI from '@/api/aim'

export default {
  components: {
    FieldError,
    Modal,
  },
  data () {
    return {
      verId: '',
      ruleId: '',
      implemented_on: '',
      error: '',
    }
  },
  methods: {
    reset () {
      this.verId = ''
      this.ruleId = ''
      this.implemented_on = ''
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /**
     * @description open modal
     */
    show (verId, ruleId, implementedOn) {
      this.reset()
      this.verId = verId
      this.ruleId = ruleId
      this.implemented_on = implementedOn
      this.$refs.modal.show()
    },
    update () {
      const implementedOn = this.implemented_on === '' ? null : this.implemented_on
      aimAPI.updateRuleImpDate(this.verId, this.ruleId, implementedOn).then(resp => {
        this.cancel()
        this.$emit('update', resp)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
