<template>
  <div
    class="d-flex flex-column flex-grow-1"
    style="min-height: 0px;"
  >
    <h5>Differences between Version {{ num1 }} and {{ num2 }}</h5>
    <div
      class="d-flex flex-wrap-reverse border-left"
      style="font-size: 12px;"
    >
      <div
        v-for="(s, index) in sheet_diffs"
        :key="s.name_old + s.name_new"
        class="border-top border-right text-nowrap"
      >
        <div
          v-if="index === sheetIndex"
          class="p-1"
          :class="{ aimnew: s.is_new, aimold: s.is_old }"
          style="font-weight: 600;"
        >
          {{ s.name_new || s.name_old }}
        </div>
        <div
          v-else
          class="text-nowrap p-1 pointer-hover"
          :class="{ aimnew: s.is_new, aimold: s.is_old }"
          @click="sheetIndex = index"
        >
          {{ s.name_new || s.name_old }}
        </div>
      </div>
    </div>
    <div
      class="overflow-auto flex-shrink-1 mb-2"
      style="min-height: 0px;"
    >
      <table
        v-if="diffs"
        class="vuetable custom-vuetable table sticky-table-header"
      >
        <thead>
          <tr>
            <th
              v-for="f in diffs.field_diffs"
              :key="f.value"
              :class="{ aimnew: f.is_new, aimold: f.is_old }"
            >
              <div>{{ f.value }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="r in diffs.rule_diffs"
          >
            <tr
              :key="`${r.id_old}:${r.id_new}:1`"
              :class="{ aimnew: r.is_new, aimold: r.is_old }"
            >
              <template v-for="(f, i) in diffs.field_diffs">
                <td
                  v-if="Object.keys(diffs.custom_fields).includes(f.value) && r.data[i].old === '' && r.data[i].new === ''"
                  :key="f.value"
                  rowspan="2"
                >
                  <div
                    v-for="cf in r[diffs.custom_fields[f.value]]"
                    :key="cf.value"
                    class="text-nowrap"
                    :class="{ aimnew: cf.is_new, aimold: cf.is_old }"
                  >
                    {{ cf.value }}
                  </div>
                </td>
                <td
                  v-else-if="!r.is_old && !r.is_new && !f.is_old && !f.is_new && r.data[i].old === r.data[i].new"
                  :key="f.value"
                  rowspan="2"
                >
                  {{ r.data[i].old }}
                </td>
                <td
                  v-else-if="r.is_old || f.is_old || (!r.is_new && !f.is_new && r.data[i].new === '')"
                  :key="f.value"
                  class="aimold"
                  rowspan="2"
                >
                  {{ r.data[i].old }}
                </td>
                <td
                  v-else-if="r.is_new || f.is_new || (!r.is_old && !f.is_old && r.data[i].old === '')"
                  :key="f.value"
                  class="aimnew"
                  rowspan="2"
                >
                  {{ r.data[i].new }}
                </td>
                <td
                  v-else
                  :key="f.value"
                  class="aimold"
                >
                  {{ r.data[i].old }}
                </td>
              </template>
            </tr>
            <tr
              :key="`${r.id_old}:${r.id_new}:2`"
            >
              <template v-for="(f, i) in diffs.field_diffs">
                <td
                  v-if="!r.is_new && !r.is_old && !f.is_new && !f.is_old && r.data[i].old !== r.data[i].new && r.data[i].old !== '' && r.data[i].new !== ''"
                  :key="f.value"
                  class="aimnew"
                >
                  {{ r.data[i].new }}
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div>
      <p
        v-if="error"
        class="text-danger"
      >
        {{ error }}
      </p>
      <button
        class="btn btn-default"
        @click.prevent="$router.go(-1)"
      >
        Back
      </button>
    </div>
  </div>
</template>

<script>
import aimAPI from '@/api/aim'

export default {
  props: {
    aimId: {
      type: [String, Number],
      required: true,
    },
    num1: {
      type: [String, Number],
      required: true,
    },
    num2: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      version_diff: {
        sheet_diffs: [],
      },
      sheetIndex: 0,
      error: '',
    }
  },
  computed: {
    diffs () {
      return this.sheet_diffs[this.sheetIndex]
    },
    sheet_diffs () {
      if (this.version_diff && this.version_diff.sheet_diffs) {
        return this.version_diff.sheet_diffs
      }
      return []
    },
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    getData () {
      aimAPI.getVersionDiff(this.aimId, this.num1, this.num2).then(resp => {
        this.version_diff = resp
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  font-size: 10px;
  /*
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  margin-bottom: 1rem;

  td,th {
    // padding: 0;
    border-right: 1px solid rgb(128, 128, 128);
    border-bottom: 1px solid rgb(128, 128, 128);

    &:first-child {
      border-left: 1px solid rgb(128, 128, 128);
    }

    div {
      text-align: center;
      padding: 0px 2px;

      &.custom-field {
        white-space: nowrap;
      }
    }
  }

  th {
    border-top: 1px solid rgb(128, 128, 128);
    background-color: rgb(240, 240, 240);
    position: sticky;
    top: 0px;
    font-weight: normal;
  }
  */
  tr:not(.aimold) td.aimold:not([rowspan]) {
    border-bottom-width: 0px;
  }
  td[rowspan], td.aimnew {
    border-bottom-width: 2px;
  }
}
.aimold {
  background-color: rgb(247, 200, 200);
}
.aimnew {
  background-color: rgb(200, 255, 200);
}
</style>
