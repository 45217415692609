<template>
  <Modal
    ref="modal"
    title="Save Version"
  >
    <BaseForm
      submit-title="Save"
      @submit="save"
      @cancel="cancel"
    >
      <BaseInput
        v-model="descr"
        :maxlength="64"
        title="Description"
        name="descr"
        type="text"
        placeholder="Description"
        required
        trim
      />
      <FieldError v-model="error" />
    </BaseForm>
  </Modal>
</template>

<script>
/**
 * Modal to update an AIM Version.
 */
import Modal from '@/components/Modal'
import FieldError from '@/components/FieldError'
import aimAPI from '@/api/aim'

export default {
  components: {
    FieldError,
    Modal,
  },
  props: {
    version: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      descr: '',
      error: '',
    }
  },
  methods: {
    reset () {
      this.descr = ''
      this.error = ''
    },
    cancel () {
      this.$refs.modal.hide()
      this.reset()
    },
    /** show modal to edit aim version */
    show () {
      this.reset()
      this.$refs.modal.show()
    },
    save () {
      const v = { aim_id: this.version.aim_id, number: this.version.number, descr: this.version.descr, locked: true }
      if (this.descr) {
        v.descr = this.descr
      }
      aimAPI.updateVersion(v).then(resp => {
        this.cancel()
        this.$emit('save-version', resp)
      }).catch(error => {
        this.error = error.message
      })
    },
  },
}
</script>
